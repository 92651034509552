import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import { mapGetters } from 'vuex'
import SectionConfigurationService from '@/calendesk/models/SectionConfigurationService'
import Service from '@/calendesk/models/DTO/Response/Service'

export default mixins(Section).extend({
  data () {
    return {
      presentWithCategories: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      categoryAndServices: 'category/categoryWithServices',
      services: 'service/getServices'
    }),
    list (): Service[] {
      if (this.services) {
        const selectedServiceIds = this.data.configuration.wb_service_list__services__.map((item: SectionConfigurationService) => item.id)
        return this.services.filter((service: Service) => selectedServiceIds.includes(service.id))
      }

      return []
    }
  }
})
